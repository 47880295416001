import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Link } from '@reach/router'
import { StaticImage } from 'gatsby-plugin-image'

const ApproachPage = () => (
  <Layout>
    <Seo
      title="Herangehensweise"
      description="Meine Herangehensweise beruht auf Tiefenpsychologie, systemischer Perspektive und Ressourcenorientierung."
      keywords={[
        'Herangehensweise',
        'Tiefenpsychologie',
        'Systemisch',
        'Ressourcenorientiert',
        'Beratung',
      ]}
    />
    <h1>Herangehensweise</h1>
    <p>
      Die von mir angebotene Beratung ist ganzheitlich ausgerichtet und dient
      der Klärung, Orientierung und Lösungserarbeitung in spezifischen Problem-
      und Lebenslagen. Meine Herangehensweise beruht auf drei
      Grundausrichtungen, die ich auf den Unterseiten näher erläutern möchte:
    </p>
    <ul>
      <li>
        Die{' '}
        <Link to="/herangehensweise/tiefenpsychologisch/">
          tiefenpsychologisch
        </Link>{' '}
        orientierte Beratung bezieht unbewusste Motive und die eigene
        Lebensgeschichte mit ein.
      </li>
      <li>
        Die{' '}
        <Link to="/herangehensweise/systemisch/">systemische Perspektive</Link>{' '}
        versteht den Menschen vor dem Hintergrund seiner inner- und
        außerfamiliären Beziehungen und seines sozialen und gesellschaftlichen
        Umfelds.
      </li>
      <li>
        Die{' '}
        <Link to="/herangehensweise/ressourcenorientiert/">
          ressourcenorientierte Vorgehensweise
        </Link>{' '}
        fokussiert weniger auf Schwächen und Defizite als mehr auf vorhandene
        Stärken und Potenziale.
      </li>
    </ul>
    <section className="flex flex-column flex-row-ns w-100 justify-around">
      <article className="relative w-100 ma2 ml0">
        <Link to="/herangehensweise/tiefenpsychologisch/">
          <StaticImage
            src={'../../images/eisberg.jpg'}
            alt="Eisberg"
            imgClassName="mb2 mr2"
          />
          <div className="absolute bottom-0 bg-white-70 w-100 pa2">
            <h3>Tiefenpsychologisch</h3>
          </div>
        </Link>
      </article>
      <article className="relative w-100 ma2 ml0">
        <Link to="/herangehensweise/systemisch/">
          <StaticImage
            src={'../../images/systemisch.jpg'}
            alt="Halmafiguren"
            imgClassName="mb2 mr2"
          />
          <div className="absolute bottom-0 bg-white-70 w-100 pa2">
            <h3>Systemisch</h3>
          </div>
        </Link>
      </article>
      <article className="relative w-100 ma2 ml0">
        <Link to="/herangehensweise/ressourcenorientiert/">
          <StaticImage
            src={'../../images/baum.jpg'}
            alt="Baum"
            imgClassName="mb2 mr2"
          />
          <div className="absolute bottom-0 bg-white-70 w-100 pa2">
            <h3>Ressourcenorientiert</h3>
          </div>
        </Link>
      </article>
    </section>
  </Layout>
)

export default ApproachPage
